<template>
  <div class="hours-search-container">
    <el-date-picker
      class="search_item"
      type="dates"
      v-model="searchquery.dateList"
      placeholder="选择一个或多个日期"
      placement="bottom-start"
      ref="datePick"
      value-format="yyyy-MM-dd"
      @change="() => {
        isLoading = false
        handleAutoSearch()
      }"
      :clearable="false"
      :picker-options="pickerOptions"
    >
    </el-date-picker>
    <a-select
      class="search_item"
      :allowClear="true"
      placeholder="请选择账号"
      v-model="searchquery.adminUserIds[0]"
      v-if="role === 'SUPER_ADMIN'"
      @change="changeUserId"
      :filter-option="filterOption"
      showSearch
    >
      <a-select-option v-for="item in adminList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
    </a-select>
    <m-select-more
      class="search_item"
      v-model="searchquery.appIdList"
      :allData="appList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="应用"
      @change="changeAppIds"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.placeIdList"
      :allData="placeList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="广告位"
      @change="changePlaceIds"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.groupIdList"
      :allData="groupList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="流量分组"
      @change="handleAutoSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.sourceIdList"
      :allData="sourceList"
      :searchById="true"
      :hasIcon="false"
      :showId="true"
      label="广告源"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.channelIdList"
      :allData="channelList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="渠道"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.positionIdList"
      :allData="positionList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="广告样式"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.platIdList"
      :allData="platList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="广告平台"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.osIdList"
      :allData="[
        { name: '安卓', id: '1' },
        { name: 'IOS', id: '0' }
      ]"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="操作系统"
      :showSelectedPart="false"
      width="240px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
    <div class="search_item input">
      <a-input placeholder="请输入SDK版本号" v-model.trim="searchquery.sdkVersion" @input="changeInputValue" />
    </div>
    <div class="search_item input">
      <a-input placeholder="请输入应用版本" allowClear v-model.trim="searchquery.appVersion" @input="changeInputValue" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getList'
import moment from 'moment'
import { toDate } from '@/utils/toTimer'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        dateList: [],
        adminUserIds: [],
        appIdList: [],
        placeIdList: [],
        groupIdList: [],
        channelIdList: [],
        positionIdList: [],
        platIdList: [],
        osIdList: [],
        groupByList: [],
        sdkVersion: '',
        appVersion: '',
        page: 1,
        limit: 10
      },
      pickerOptions: {
        disabledDate (time) {
          const date = '2021-08-31'
          // 禁选今天以后 或  2021年9月之前的 time < moment(date)
          return time.getTime() > Date.now() || time < moment(date)
        },
        onPick () {
        }
      },
      isLoading: false
    }
  },
  watch: {
    'searchquery.dateList': {
      handler (val) {
        if (val.length > 5) {
          this.searchquery.dateList.splice(0, 1)
        }
      }
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title
    })
  },
  created () {
    // 初始化时间
    // 今天
    const today = toDate(Date.now())
    // 昨天
    const yesterday = toDate(Date.now() - 24 * 60 * 60 * 1000)
    this.searchquery.dateList = [yesterday, today]
    this.getAdminList()
    this.getAppList()
    this.getPlaceList()
    this.getGroupList()
    this.getSourceList()
    this.getChannelList()
    this.getPositionList()
    this.getPlatList()
    if (this.$route.params && this.$route.params.r && this.$route.params.r.appId) {
      this.searchquery.appIdList.push(this.$route.params.r.appId)
    }
    if (this.$route.params && this.$route.params.r && this.$route.params.r.placeId) {
      this.searchquery.placeIdList.push(this.$route.params.r.placeId)
    }

    if (this.$route.params && this.$route.params.record) {
      this.searchquery.dateList = [this.$route.params.record.oldDate, this.$route.params.record.newDate]
    }
    // 进入时搜索
    this.$emit('handleAutoSearch', this.searchquery)
  },
  methods: {
    sortDownDate (a, b) {
      return Date.parse(a.received) - Date.parse(b.received)
    },

    // sdk版本、应用版本输入框修改
    changeInputValue () {
      this.isLoading = false
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.handleAutoSearch()
      }, 1000)
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    onPick (e, f, g) {
    },
    // 账户改变查应用
    async changeUserId (id) {
      this.isLoading = false
      if (id) {
        // userId有值，查app
        await this.getPlatListByuser({ id })
        await this.getAppListByAdminId(id)
      } else {
        // userId为空，查app
        await this.getPlatList()
        await this.getAppList()
      }
      await this.changeAppIds(this.searchquery.appIdList)
    },
    // 应用改变查广告位
    async changeAppIds (ids) {
      if (ids.length === 0) {
        // app无值 通过全部appList查place列表
        await this.getPlaceListByAppIds(this.appList.map((item) => item.id))
      } else {
        // app选了 通过选了的app查place列表
        await this.getPlaceListByAppIds(ids)
      }
      this.changePlaceIds(this.searchquery.placeIdList)
    },
    // 广告位改变查流量分组
    async changePlaceIds (ids) {
      if (ids.length === 0) {
        // 广告位无值，通过全部placelist查询group列表
        await this.groupListByAdplace(this.placeList.map((item) => item.id))
        await this.getSourceListByAdplace(this.placeList.map((item) => item.id))
      } else {
        // 广告位选了，通过选中的place查询group列表
        await this.groupListByAdplace(ids)
        await this.getSourceListByAdplace(ids)
      }
      this.handleAutoSearch()
    },
    handleAutoSearch () {
      if (this.isLoading) return
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
      this.searchquery.dateList.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
      this.$emit('handleAutoSearch', this.searchquery)
      this.isLoading = true
    }
  }
}
</script>

<style lang="less">
.hours-search-container {
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .search_item {
    padding: 10px 5px;
    width: 220px;
    display: inline-block;
  }
}
</style>
