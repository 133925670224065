export const toDate = (n, type) => {
  const time = new Date(n)
  // 年
  const y = time.getFullYear()
  // 月
  const m = (time.getMonth() + 1 + '').padStart('2', '0')
  // 日
  const d = (time.getDate() + '').padStart('2', '0')
  // 时
  const hh = (time.getHours() + '').padStart('2', '0')
  // 分
  const mm = (time.getMinutes() + '').padStart('2', '0')
  // 秒
  const ss = (time.getSeconds() + '').padStart('2', '0')
  if (type === 'YYYY-MM-DD hh:mm:ss') {
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
  } else if (type === 'YYYY/MM/DD hh:dd:ss') {
    return `${y}/${m}/${d} ${hh}:${mm}:${ss}`
  } else if (type === 'YYYY-MM-DD_hhmmss') {
    return `${y}-${m}-${d}_${hh}${mm}${ss}`
  } else {
    return `${y}-${m}-${d}`
  }
}
