<template>
  <div class="hours-Report-container">
    <HoursSearth @handleAutoSearch="handleAutoSearch" />
    <HourChart :query="query" />
    <HourTable :query="query" />
  </div>
</template>

<script>
import HoursSearth from './HoursSearch'
import HourChart from './HourChart'
import HourTable from './HourTable'
export default {
  name: 'HoursReport',
  components: { HoursSearth, HourChart, HourTable },
  data () {
    return {
      query: {}
    }
  },
  methods: {
    handleAutoSearch (val) {
      let query = JSON.parse(JSON.stringify(val))
      if (val.adminUserIds.length === 1 && !val.adminUserIds[0]) {
        query = { ...val, adminUserIds: [] }
      }
      this.query = query
    }
  }
}
</script>

<style lang="less">
.hours-Report-container {
  margin: 0 10px 10px 10px;
}
</style>
